<template>
  <MXContainerWrapper>
    <v-container fluid >
      <v-row>
        <v-col>Edit</v-col>
      </v-row>
    </v-container>
  </MXContainerWrapper>
</template>

<script>
import env from '../env';
import MXContainerWrapper from "../components/MXContainerWrapper";


export default {
  name: `${env.view.name}EditView`,
  components: {MXContainerWrapper},
  data() {
    return {
      preloader: false,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
